import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import QuestionsView from '@/views/QuestionsView.vue'
import ReportView from '@/views/ReportView.vue'
import SignalReportView from '@/views/SignalReportView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/list/:deptCode/:status',
    name: 'home',
    component: HomeView
  },
  {
    path: '/qst',
    name: 'qst',
    component: QuestionsView
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView
  },
  {
    path: '/selfreport',
    name: 'selfreport',
    component: SignalReportView
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
