
import request from '@/utils/request'


// 查询被评估对象列表
export function listScales(query) {
    return request({
      url: '/wx/cga/scale/listByDeptId/'+query,
      method: 'get'
    })
  }

// 查询被评估对象列表
export function listQuestionsByScaleId(query) {
  return request({
    url: '/wx/cga/scale/'+query,
    method: 'get'
  })
}

// 查询被评估对象列表
export function listQuestions(query) {
  return request({
    url: '/wx/cga/question/getQuestions/'+query,
    method: 'get'
  })
}



// 查询被评估对象列表
export function listQuestionItemsById(query) {
  return request({
    url: '/wx/cga/qstitem/'+query,
    method: 'get'
  })
}


// 查询被评估对象列表
export function listJudgesByScaleId(query) {
  return request({
    url: '/wx/cga/judgeitem/'+query,
    method: 'get'
  })
}

  // 新增被评估结果
export function addRecord(data) {
    return request({
      url: '/wx/cga/scale/saveAssessResult',
      method: 'post',
      data: data
    })
  }

  
  // 新增被评估结果
export function addAgedPeople(data) {
  return request({
    url: '/wx/cga/people',
    method: 'post',
    data: data
  })
}