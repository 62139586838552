<template>
    <div>
        <div class="tabbar">
            <div class="report-title">
                <div class="goback" @click="toBack">
                    <el-image  :src="require('@/assets/images/back.png')"></el-image>
                </div>
                <div class="title">评估报告</div>
            </div>
        </div>
        <!-- 评估内容与结果 -->
        <div class="cnt-rslt">
            <div class="ctn-rslt-title">评估内容与结果</div>
            <div class="scales">
                <div v-for="(item,index) in report" class="scale-item">
                    <div>{{ item.scaleName }}</div>
                    <div :class="item.isNormal == 0 ? 'unnormal':'normal'">{{ item.item.itemDesc }}</div>
                </div>
            </div>
        </div>
        <!-- 健康指导 -->
        <div class="guids">
            <div class="guid-title">
                健康指导
            </div>
            <div class="guid-cnts">
                <div v-for="(item,index) in report" class="guid-card">
                    <div class="guid-result">
                        <div>{{ item.scaleName }}</div>
                        <div :class="item.isNormal == 0 ? 'unnormal':'normal'">{{ item.item.itemDesc }}</div>
                    </div>
                    <div class="guid-result-cnt">
                        <div v-for="(itm,indx) in item.item.guids">
                            <p class="font-p">
                                {{ itm.guidContent }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 建议 -->
        <div class="tips">
            提示信息仅供参考，不直接作为临床诊断的依据!
        </div>
    </div>
</template>


<script>

    export default {
        data(){
         return {
            msg:'',
            report:[]
         }
        },
        mounted(){
            this.getList();
        },
        methods: {
            toBack(){
                    
                let depcode = localStorage.getItem("deptcode");
                let url = '/list/'+depcode+'/1';
                this.$router.push(url)
                // this.$router.push('/list/3/1');
            },
            getList(){
                this.report = JSON.parse(localStorage.getItem('results'));
            }
        },
    }

</script>


<style scoped>

.tabbar{
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
}

.goback{
    height: 2rem;
    width: 2rem;
    margin: 0 1rem;
}
.report-title{
    height: 5rem;
    line-height: 5rem;
    display: flex;
    align-items: center;
    background-color: #1A4DCA;
    color: #fff;
   font-size: 1.25rem;
   
}



.cnt-rslt,.guids{
    border-left: 1px solid #9e9e9e;
    border-right: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 1rem;
    padding-bottom: 1rem;
}
.cnt-rslt{
    margin: 6rem 0.8rem 0 0.8rem;
}
.guids{
    margin: 2rem 0.8rem 0 0.8rem;
}


.ctn-rslt-title,.guid-title{
    background-color: #54A6FC;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    height: 3.5rem;
    line-height: 3.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    color: #fff;
    display: flex;
}

.scale-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    height: 4rem;
    line-height: 4rem;
    background-color: #fff;
    border-top: 1px solid #9e9e9e;
}

.normal{
    color: #9e9e9e;
}

.unnormal{
    color: red;
}

.guids{
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.guid-cnts{
    margin-top: 1rem;
}


.guid-result{
    background-color:#f1f1f1;
    height: 3rem;
    line-height: 3rem;
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    justify-content: space-between;
}

.normal{
    color:#9e9e9e;
}

.font-p{
    text-align: left;
    background-color: #fff;
    color: #9e9e9e;
    /* line-height: 2rem; */
    padding: 0 0.5rem;
}

.tips{
  color: #E8B627;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  padding-bottom: 3rem;
}

</style>