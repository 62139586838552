import axios from 'axios'

axios.defaults.headers['code'] = 'WX_SELF'
axios.defaults.headers.post['Access-Control-Allow-Origin']='*';
axios.defaults.headers.post['Content-Type'] = 'application/json'

// 创建axios实例 Access-Control-Allow-Origin
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: "http://localhost:5375/self",
    // baseURL: "https://www.aiagedcare.cn:5375/cgaselfapi",
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
  })

  

  export default service