/**
 * 批量号生成
 */
export function createBatchNum(){

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let rand4 = Math.floor(Math.random()*10000);
    return  (year + '') +
            (month.length < 2 ?'0' + month : (month+'')) +
            (day.length< 2?'0'+day:(day+'')) +
            (hour.length < 2?'0'+hour : (hour+'')) +
            (minute.length < 2?'0'+minute : (minute+'')) +
            (second.length < 2?'0'+second : (second+'')) +
            (rand4 + '');
};