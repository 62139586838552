<template>
    <div>
        <div class="qst-title">
            <div>{{ scaleName }}</div>
            <div>{{currentIndex+1}} / {{allCount}}题</div>
        </div>
        <div class="qst" >
            <div class="qst-desc">
                <p>
                   {{ currentIndex+1 }}.{{ currentqst.qstTitle }}
                </p>
            </div>
            <div class="qst-fc" v-for="(item,index) in currentqst.qstItems" @click="btnQstItemClick(item)">
                <div class="qst-item">
                    <el-image v-if="currentSelected != item.id" :src="require('@/assets/images/unselected.png')"/>
                    <el-image v-if="currentSelected == item.id" :src="require('@/assets/images/selected.png')"/>
                    <div :id="item.id" class="slt-cnt">{{ item.itemName }}</div>
                </div>
            </div>
        </div>
        <div class="btns">
            <div class="btn" @click="btnCancelClick" v-if="currentIndex == 0 ? true:false">取  消</div>
            <div :class="currentIndex == 0 ? 'btn':'btn marginleft30'" @click="btnPreClick" v-if="currentIndex > 0 ? true:false">上一步</div>
            <div :class="currentIndex >= 0 && currentIndex < questions.length -1 ? 'btn marginleft30':'btn' " @click="btnNextClick" v-if="currentIndex < questions.length -1 ? true:false">下一步</div>
            <div :class="currentIndex == questions.length -1 ? 'btn marginleft30' :'btn' " v-if="currentIndex == questions.length -1 ? true:false" @click="btnSave">保  存</div>
            
        </div>
    </div>
</template>

<script>
import {listQuestions,addRecord} from '@/api/common'

export default{
    
    data(){
        return {
            radio1:'',
            btnVisible:false,
            questions:[],
            scaleName:'',
            scaleId:'',
            allCount:0,
            currentIndex:0,
            currentqst:{},
            currentSelected:0,
            selectedRadios:[]
        }
    },
    mounted(){
        this.scaleName = this.$route.query['scaleName'];
        this.scaleId = this.$route.query['scaleId'];
        this.allCount = this.$route.query['allCount'];
        this.getList();
    },
    methods: {
        getList(){
            
            listQuestions(this.scaleId).then(res=>{
                this.questions = res.data.data;
                if(this.questions!=null && this.questions.length>0){
                    this.currentqst = this.questions[0];
                }
            })
        },
        btnCancelClick(){
            this.$confirm('返回后问卷将不会保存，您确定返回吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    
                    let depcode = localStorage.getItem("deptcode");
                    let url = '/list/'+depcode+'/1';
                    this.$router.push(url)
                }).catch(() => {
                             
                });
        },
        btnPreClick(){
            this.currentIndex--;
            if(this.currentIndex < 0)
                this.btnVisible = true;
            this.currentqst = this.questions[this.currentIndex];
       
            if(this.selectedRadios!=null && this.selectedRadios.length>0){
                let pObjs = this.selectedRadios.filter(item=>item.pid === this.currentqst.id);
                
                if(pObjs != null && pObjs.length>0){
                    this.currentSelected = pObjs[0].cid;
                }
            }
        },
        btnNextClick(){

            if(this.currentIndex + 1 >  this.selectedRadios.length){
                this.$message({
                message: '请选择第'+(this.currentIndex+1)+'题，再点击下一步!',
                type: 'warning'
                });
                // alert('请选择第'+(this.currentIndex+1)+'题，再点击下一步!')
                return;
            }
            this.currentIndex++;

            if(this.currentIndex > this.questions.length)
                this.btnVisible = true;
            this.currentqst = this.questions[this.currentIndex];
            
            if(this.selectedRadios!=null && this.selectedRadios.length>0){
                let pObjs = this.selectedRadios.filter(item=>item.pid === this.currentqst.id);
                
                if(pObjs != null && pObjs.length>0){
                    this.currentSelected = pObjs[0].cid;
                    return;
                }
            }
        },
        btnSave(){
            if(this.currentIndex + 1 >  this.selectedRadios.length){
                this.$message({
                    message: '请选择第'+(this.currentIndex+1)+'题，再点击保存!',
                    type: 'warning'
                });
                // alert('请选择第'+(this.currentIndex+1)+'题，再点击保存!')
                return;
            }
            this.currentIndex++;
            let data= {
                    scaleId:this.scaleId,
                    results:JSON.stringify(this.selectedRadios),
                    isBegin:1,
                    isSave:1
                }
            
            
            this.$router.push({path:'/selfreport',query:{
                data:data
            }})
            
        },
        btnQstItemClick(data){
            this.currentSelected = data.id;
            this.getRadios(data.qstId,data.id)

            if(this.currentIndex < this.questions.length-1){
                setTimeout(() => {
                    this.btnNextClick();
                }, 400);
            }
        },
        getRadios(pid,cid){
            let news = this.selectedRadios.filter(item=>item.pid != pid);
            news.push({pid:pid,cid,cid})
            this.selectedRadios = news;
        }
    },
}

</script>

<style scoped>

    .qst-title{
        height: 5rem;
        line-height: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #1A4DCA;
        color: #fff;
        font-size: 1.25rem;
    }
    .qst{
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 1.25rem;
    }
    .qst-desc{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1); */
        padding: 0 1rem;
    }

    .qst-desc p{
        text-align: left;
        word-break: break-all;
    }

    .qst-fc{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        font-size: 1.25rem;
    }

    .qst-item{
        margin-top: 1rem;
        display: flex;
        align-items: center;
    }
    .slt-cnt{
        margin-left: 0.5rem;
    }

    .el-image{
        width: 2rem;
        height: 2rem;
    }

    .btns{
        margin: 3rem 0.5rem 0 0.5rem;
        width: calc(100% - 2rem);
        display: flex;
        justify-content: center;
    }
    
    .btn{
        width: 50%;
        background-color: #54A6FC;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        line-height: 3rem;
        border-radius: 1rem;
        color: #fff;
        font-size: 1.25rem;
    }
   .marginleft30{
    margin-left: 1.5rem;
   }
</style>